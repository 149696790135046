import React, { useMemo } from "react"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
export default function FooterView() {
  const { t , i18n} = useTranslation()
  const about = useMemo(() => {
    return [
      {
        label: capitalizedFirst(t("footer.about-data.about-us")),
        link: `${process.env.REACT_APP_HOME_DOMAIN_URL}/help-center/about-us`,
      },
      {
        label: capitalizedFirst(t("footer.about-data.condition")),
        link: `${process.env.REACT_APP_HOME_DOMAIN_URL}/help-center/term`,
      },
      {
        label: capitalizedFirst(t("footer.about-data.privacy")),
        link: `${process.env.REACT_APP_HOME_DOMAIN_URL}/help-center/privacy`,
      },
      {
        label: capitalizedFirst(t("footer.about-data.security")),
        link: `${process.env.REACT_APP_HOME_DOMAIN_URL}/help-center/privacy`,
      },
      {
        label: capitalizedFirst(t("footer.about-data.regulations")),
        link: `${process.env.REACT_APP_HOME_DOMAIN_URL}/help-center/operation-rule`,
      },
      {
        label: capitalizedFirst(t("footer.about-data.faq")),
        link: `${process.env.REACT_APP_HOME_DOMAIN_URL}/help-center/send-us-feedback`,
      },
    ]
  }, [i18n.language])
  const clickItem = (link: string) => {
    if (link) {
      window.open(link, "_blank")
    }
  }

  const booking = useMemo(() => {
    return [
      {
        label: capitalizedFirst(t("footer.booking-data.flight")),
        link: `${process.env.REACT_APP_FLIGHT_BETA_DOMAIN_URL}/booking/flight`,
      },
      {
        label: capitalizedFirst(t("footer.booking-data.hotel")),
        link: process.env.REACT_APP_HOTEL_BETA_DOMAIN_URL,
      },
      {
        label: capitalizedFirst(t("footer.booking-data.car")),
        link: `${process.env.REACT_APP_BOOKING_CAR_BETA_DOMAIN_URL}/booking/car`,
      },
      {
        label: capitalizedFirst(t("footer.booking-data.tour")),
        link: process.env.REACT_APP_TOUR_BETA_DOMAIN_URL,
      },
      {
        label: capitalizedFirst(t("footer.booking-data.restaurant")),
        link: process.env.REACT_APP_FOOD_DOMAIN_URL,
      },
      {
        label: capitalizedFirst(t("footer.booking-data.shop")),
        link: process.env.REACT_APP_ECOMMERCE_DOMAIN_URL,
      },
    ]
  }, [i18n.language])
  const otherServices = useMemo(() => {
    return [
      {
        label: capitalizedFirst(t("footer.order-data.partner")),
        link: `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/request-open-shop`,
      },
      {
        label: capitalizedFirst(t("footer.order-data.program")),
        link: `${process.env.REACT_APP_HOME_DOMAIN_URL}/help-center/about-us`,
      },
      {
        label: capitalizedFirst(t("footer.order-data.feedback")),
        link: `${process.env.REACT_APP_HOME_DOMAIN_URL}/help-center/send-us-feedback`,
      },
    ]
  }, [i18n.language])

  return (
    <footer className="bg-white z-[2]">
      <div className="mx-auto max-w-[1200px] gap-4 py-[16px] md:py-[24px] px-3">
        <div className="mx-auto flex  flex-col justify-between gap-4 pb-4 lg:flex-row">
          <div className="flex w-full flex-[3] flex-wrap justify-between gap-3">
            {/* ABOUT */}
            <div className="flex-1">
              <h3 className="mb-1 font-semibold lg:mb-3 md:text-[16px] text-[12px]">
                {capitalizedFirst(t("footer.about", {value: process.env.REACT_APP_TITLE}))}
              </h3>
              <ul className="flex flex-col gap-1 lg:gap-3">
                {about.map((item) => (
                  <li
                    className="cursor-pointer hover:underline md:text-[16px] text-[12px]"
                    onClick={() => clickItem(item.link)}
                    key={item.label}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
            </div>
            {/* BOOKING */}
            <div className="flex-1">
              <h3 className="mb-1 font-semibold lg:mb-3 md:text-[16px] text-[12px]">
                {capitalizedFirst(t("footer.booking"))}
              </h3>
              <ul className="flex flex-col gap-1 lg:gap-3">
                {booking.map((item) => (
                  <li
                    className="cursor-pointer hover:underline md:text-[16px] text-[12px]"
                    onClick={() => clickItem(item.link as string)}
                    key={item.label}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
            </div>
            {/* other */}
            <div className="flex-1 ">
              <h3 className="mb-1 font-semibold lg:mb-3 md:text-[16px] text-[12px]">
                {capitalizedFirst(t("footer.order-service"))}
              </h3>
              <ul className="flex flex-col gap-1 lg:gap-3">
                {otherServices.map((item) => (
                  <li
                    className="cursor-pointer hover:underline md:text-[16px] text-[12px]"
                    onClick={() => clickItem(item.link as string)}
                    key={item.label}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-1 justify-center gap-3 pt-1 md:pt-4 lg:hidden">
          <span className="text-nowrap text-[14px]">
            {capitalizedFirst(
              t("footer.all-right", { name: process.env.REACT_APP_TITLE })
            )}
          </span>
        </div>
      </div>
    </footer>
  )
}
